import { Flex } from "@aws-amplify/ui-react";
import ClinicalTrialFrameCollection from "./ui-components/ClinicalTrialFrameCollection";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";
import { DataStore } from "aws-amplify";
import { ClinicalTrial } from "./models";
function Trials({searchText}) {

  return (
    <Flex justifyContent={"center"}>
      <ClinicalTrialFrameCollection/>
    </Flex>
  );
}
export default Trials;
