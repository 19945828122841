import {
  Button,
  Divider,
  Flex,
  Loader,
  Icon,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { FCProvider, FCPatient, FCPatientFile, Patient } from "./models";
import { DataStore, API } from "aws-amplify";
import FCPatientCollection from "./ui-components/FCPatientCollection";
import CreateFCPatient from "./ui-components/CreateFCPatient";
import CreateFCPatientHack from "./ui-components/CreateFCPatientHack";
import DocImport from "./docImport.tsx";

function MyPatients({ user, navigate }) {
  const [patients, setPatients] = useState([]);
  const [patientFiles, setPatientFiles] = useState([]);
  const [provider, setProvider] = useState();
  const [showCreatePatient, setShowCreatePatient] = useState(false);
  const [newPatientFile, setNewPatientFile] = useState();
  const [newPatient, setNewPatient] = useState();
  const [apiWait, setApiWait] = useState(false);
  const apiName = "fcdataapi";
  const summaryApiPath = "/gettrials";
  const username = user.username;

  const getProvider = async () => {
    const c = await DataStore.query(FCProvider, (c) => c.username.eq(username));
    console.log("provider: ");
    console.log(c);
    if (c.length > 0) {
      console.log("provider exists");
      setProvider(c[0]);
      setPatients(c[0].FCPatients?.items);
      if (c[0].FCPatients?.length > 0) {
        setNewPatient(patients[patients?.length() - 1].values.id);
      }
    } else {
      navigate("/welcome");
    }
  };

  const addPatientFile = async (key, text) => {
    console.log("adding patient file: " + key);
    let newFile = new FCPatientFile({
      fileName: key,
      s3Key: key,
      fileText: text,
    });
    console.log("adding new patient file: ", newFile.key);

    setNewPatientFile(newFile);
    console.log(newPatientFile);

    setPatientFiles((patientFiles) => [...patientFiles, newFile]);
    console.log("patient files: ");
    console.log(patientFiles);
  };

  const addPatientFiles = async (fcpatientID) => {
    console.log("*****adding patient files for patient");
    for (let i = 0; i < patientFiles.length; i++) {
      let patientFile = new FCPatientFile({
        fileName: patientFiles[i].fileName,
        s3Key: patientFiles[i].s3Key,
        fileText: patientFiles[i].fileText,
        fcpatientID: fcpatientID,
      });
      console.log(patientFile);
      try {
        const f = await DataStore.save(patientFile);
        console.log(f);
      } catch (err) {
        console.log(err);
      }
    }
    setPatientFiles([]);
  };

  function getAge(dob) {
    if (!dob) return "Unknown";

    var birthDate = new Date(dob);
    var today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const getTrials = async (patient) => {
    console.log("getting trials answer");
    setApiWait(true);
    if (!patient.diagnoses) {
      console.log("no aptient entered");
    } else {
      //setChatText(`${chatText}\n${user}: ${question}`)

      var payload = {
        age: getAge(patient.dateOfBirth).toString(),
        gender: patient.sex,
        diagnosis: patient.diagnoses.toString(),
        allergies: "none",
        patient_summary: patient.summary,
      };
      const myInit = {
        headers: {}, // OPTIONAL
        body: payload, // OPTIONAL
      };
      console.log("payload: " + payload);
      let response = await API.post(apiName, "/match", myInit);

      var trials = JSON.stringify(response).split(",");

      console.log("trials: " + trials);
      const original = await DataStore.query(FCPatient, patient.id);

      const updatedPatient = await DataStore.save(
        FCPatient.copyOf(original, (updated) => {
          updated.potentialTrials = trials;
        })
      );
    }
    setApiWait(false);
  };

  const warmCache = async () => {
    console.log("warming cache");
    var payload = {
      age: "23",
      gender: "M",
      diagnosis: "sepsis",
      allergies: "none",
      patient_summary: "patient has sepsis",
    };
    const myInit = {
      headers: {}, // OPTIONAL
      body: payload, // OPTIONAL
    };
    console.log("payload: " + payload);
    let response = await API.post(apiName, "/match", myInit);
    console.log("cache warmed");
  };

  useEffect(() => {
    getProvider();
    warmCache();
  }, []);

  return (
    <Flex direction={"column"} alignItems={"center"}>
      {!showCreatePatient ? (
        <Button
          onClick={() => {
            setShowCreatePatient(true);
          }}
        >
          <Icon
            name="add"
            pathData="M13 7h-2v4h-4v2h4v4h2v-4h4v-2h-4v-4zm-1-5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
          Register New Patient
        </Button>
      ) : null}
      {showCreatePatient ? (
        <>
          <Flex direction={"row"} alignItems={"center"}>
            <CreateFCPatientHack
              width="600px"
              margin={"px"}
              onSubmit={(fields) => {
                const updatedFields = {};
                console.log("adding patient:");
                updatedFields["fcproviderID"] = provider.id;
                Object.keys(fields).forEach((key) => {
                  if (typeof fields[key] === "string") {
                    updatedFields[key] = fields[key].trim();
                  } else {
                    updatedFields[key] = fields[key];
                  }
                });
                return updatedFields;
              }}
              onSuccess={(fields, patient) => {
                setShowCreatePatient(false);
                console.log("success");
                console.log(patient);

                console.log("getting provider");
                getProvider();
                //getTrials(patient);
                let age = getAge(patient.dob);
                console.log("age: " + age);
                //setNewPatient(provider.FCPatients.items[provider.FCPatients.items.length-1]);
                //console.log("last patient id: ", newPatient.id);
                console.log("adding patient files");
                addPatientFiles(patient.id);
                getTrials(patient);
              }}
              onCancel={() => setShowCreatePatient(false)}
            />
            <Divider orientation="vertical" />
            <DocImport addPatientFile={addPatientFile} />
          </Flex>
        </>
      ) : null}
      {apiWait ? (
        <Flex
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <h5>Running New Match Process</h5>
          <Loader width="3em" height="3em" />{" "}
        </Flex>
      ) : null}
      <FCPatientCollection
        items={patients}
        overrideItems={({ item, idx }) => ({
          overrides: {
            FCPatientListItem: {
              as: "a",
              href: `/mypatient/${item.id}`,
              onClick: () => {
                console.log(item);
              },
              style: { cursor: "pointer" },
            },
            Age: { children: "Age: " + getAge(item.dateOfBirth) },

            ReviewStatusFlag: {
              children:
                item.potentialTrials?.length > 0
                  ? item.potentialTrials?.length + " Matches"
                  : "No Matches",
              backgroundColor:
                item.potentialTrials?.length > 0 ? "lightgreen" : "lightgray",
              alignItems: "center",
              textAlign: "center",
            },
          },
        })}
      />
    </Flex>
  );
}

export default withAuthenticator(MyPatients);
