import PatientSummaryFrame from "./ui-components/PatientSummaryFrame";
import {
  Button,
  Divider,
  Badge,
  Flex,
  Heading,
  Loader,
  ScrollView,
  Tabs,
  TabItem,
  Text,
  TextField,
  Placeholder,
  withAuthenticator,
  TextAreaField,
  Icon,
} from "@aws-amplify/ui-react";
import { API, DataStore, Amplify } from "aws-amplify";
import { FCPatient, FCPatientFile, ClinicalTrial } from "./models";
import { useEffect, useState } from "react";
import { Form, useParams } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import UpdateFCPatient from "./ui-components/UpdateFCPatient";
import PatientFileCollection from "./ui-components/PatientFileCollection";
import FCPatientSummaryHeader from "./ui-components/FCPatientSummaryHeader";
import ClinicalTrialFrameCollection from "./ui-components/ClinicalTrialFrameCollection";
function getAge(dob) {
  if (!dob) return "Unknown";

  var birthDate = new Date(dob);
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function MyPatient({ user }) {
  const { id } = useParams();
  const [patient, setPatient] = useState([]);
  const [patientFiles, setPatientFiles] = useState([]);
  const [chatText, setChatText] = useState("");
  const [question, setQuestion] = useState("");
  const [apiWait, setApiWait] = useState(false);
  const [apiWaitTrials, setApiWaitTrials] = useState(false);
  const [patientContext, setPatientContext] = useState("");
  const apiName = "fcdataapi";
  const summaryApiPath = "/model/gptqa";
  const trialsApiPath = "/trials";
  const [summaryText, setSummaryText] = useState("");
  const [trials, setTrials] = useState([]);
  user = user.username;

  const getPatient = async () => {
    const pat = await DataStore.query(FCPatient, id);
    console.log("patient----------");
    console.log(pat);
    setPatient(pat);
    var files = await pat.FCPatientFiles?.values;
    var summaryContent = pat.summary;
    setPatientFiles(files);
    // if (patientFiles) {
    //   console.log("patient files: ");
    //   patientFiles.forEach((file) => {
    //     summaryContent += file.fileText;
    //   });
    //   patientFiles.map((file) => {
    //     console.log("file: " + file);
    //     console.log(file);
    //   });
    // }
    getPatientContext(pat);
    apiWaitTrials ? setApiWaitTrials(false) : setApiWaitTrials(true);
    getTrials(pat.potentialTrials);
  };

  const getPatientContext = async (patient) => {
    console.log("getting summary text for patient: " + patient.id);
    setApiWait(true);
    if (!patient) {
      console.log("no patient entered");
    } else {
      var files = await patient.FCPatientFiles?.values;
      var summaryContent = patient.summary;
      summaryContent += "\nPatient age: " + getAge(patient.dateOfBirth) + "\n";
      summaryContent += "\nPatient sex: " + patient.sex + "\n";
      summaryContent += "\nPatient diagnoses: " + patient.diagnoses + "\n";
      var files = await patient.FCPatientFiles?.values;
      if (files) {
        console.log("patient files: ");
        files.forEach((file) => {
          summaryContent += file.fileText;
        });
      }
      console.log("summaryContent: " + summaryContent);
      setPatientContext(summaryContent);
      await getGPTSummary(summaryContent);
    }
  };

  const getGPTQA = async (question) => {
    console.log("getting GPT answer");
    setApiWait(true);
    if (!question) {
      console.log("no summary entered");
    } else {
      //setChatText(`${chatText}\n${user}: ${question}`)
      var payload = {
        category: "question",
        patient_question: question,
      };
      const myInit = {
        headers: {}, // OPTIONAL
        body: payload, // OPTIONAL
      };
      console.log("myInit: " + JSON.stringify(myInit));
      let response = await API.post(apiName, summaryApiPath, myInit);
      console.log("response: " + response);
      setApiWait(false);
      setChatText(`${chatText}\n${user}: ${question}\nNurse GPT: ${response}`);
    }
  };

  const handleChatSubmit = (event) => {
    event.preventDefault();
    setApiWait(true);
    console.log("chat submit");
    console.log(question);
    getGPTQA(question);
    setQuestion("");
  };

  const getGPTSummary = async (summary) => {
    console.log("getting GPT summary: " + summary);
    if (!summary) {
      // alert("Please enter a summary");
      console.log("no summary entered");
    } else {
      var payload = {
        category: "summary",
        patient_notes: summary,
        temperature: "",
        model: "",
        max_tokens: "",
      };
      const myInit = {
        headers: {}, // OPTIONAL
        body: payload, // OPTIONAL
      };
      console.log("myInit: " + JSON.stringify(myInit));
      let response = await API.post(apiName, summaryApiPath, myInit);
      console.log("response: " + response);
      setSummaryText(response);
    }
  };

  const getTrials = async (trialList) => {

    console.log("getting trials: " + trialList);
    if (trialList?.length === 0) {
      // alert("Please enter a summary");
      console.log("no trials");
    } else {

      const myInit = {
        headers: {},
        body: { nct_list:JSON.parse(trialList) },
      };
      console.log("myInit: " + JSON.stringify(myInit));
      let response = await API.post(apiName, "/trials", myInit);
      console.log("response: " + response);
      var tempTrials = [];
      response.forEach((trial) => {
        var completeDate = new Date(trial.completiondate)
          .toISOString()
          .substring(0, 10);
        var startDate = new Date(trial.startdate)
          .toISOString()
          .substring(0, 10);

        // var record = new ClinicalTrial({
        //   NCTId: trial.NCTId,
        //   title: trial.BriefTitle,
        //   condition: trial.Condition,
        //   summary: trial.BriefSummary,
        //   intervention: trial.Intervention,
        //   startDate: startDate,
        //   endDate: completeDate,
        //   status: trial.OverallStatus,
        //   inclusionCriteria: trial.InclusionCriteria,
        //   exclusionCriteria: trial.ExclusionCriteria,
        //   sex: trial.Gender,
        //   maxAge: parseInt(trial.MaximumAgeYears),
        //   minAge: parseInt(trial.MinimumAgeYears),
        //   organization: trial.OrgFullName,
        //   _version: 1,
        // });

        // var result = await API.graphql(graphqlOperation(mutations.createClinicalTrial, { input: record }))

        var newtrial = 
          new ClinicalTrial({
            NCTId: trial.nctid,
            title: trial.brieftitle,
            condition: trial.condition,
            summary: trial.briefsummary,
            intervention: trial.intervention,
            startDate: startDate,
            endDate: completeDate,
            status: trial.OverallStatus,
            inclusionCriteria: trial.inclusioncriteria,
            exclusionCriteria: trial.exclusioncriteria,
            sex: trial.gender,
            maxAge: parseInt(trial.maximumageyears),
            minAge: parseInt(trial.minimumageyears),
            organization: trial.orgfullname,
          });

        tempTrials.push(newtrial);
      
        });
        
        setTrials(tempTrials);
        apiWaitTrials ? setApiWaitTrials(true) : setApiWaitTrials(false);
    }
  };

  useEffect(() => {
    getPatient();

    return () => {
      console.log("unmounting...");
    };
  }, []);

  return (
    <>
      <Heading level={4} textAlign={"center"}>
        Patient - {patient.firstName} {patient.lastName} : {" "}
        {getAge(patient.dateOfBirth)} years old
      </Heading>

      <Flex direction="column" justifyContent={"center"}>
        <Tabs justifyContent="center">
          <TabItem align="center" title="Summary">
            <Flex
              margin={"1em 5em 1em 5em "}
              direction="column"
              justifyContent={"center"}
            >
              <FCPatientSummaryHeader fCPatient={patient} />
              <Badge textAlign={"right"}>GPT Summary</Badge>
              <ScrollView height="300px">
                {!summaryText ? (
                  <Placeholder height="300px" size="large" />
                ) : (
                  <Text
                    style={{ whiteSpace: "pre-line" }}
                    variation={"primary"}
                  >
                    {summaryText}
                  </Text>
                )}
              </ScrollView>
              <Badge textAlign={"right"}>Chat</Badge>
              {chatText ? (
                <ScrollView height="hug">
                  <Text style={{ whiteSpace: "pre-line" }}>{chatText}</Text>
                  {apiWait ? <Placeholder height="50px" size="large" /> : null}
                  <Divider />
                </ScrollView>
              ) : null}
              <form onSubmit={handleChatSubmit}>
                <Flex direction="row" justifyContent={"center"}>
                  <TextField
                    width="600px"
                    placeholder="Ask a question about your patient"
                    value={question}
                    onChange={(e) => setQuestion(e.currentTarget.value)}
                  />
                  <Button icon="airplane" type="submit">
                    ?
                  </Button>
                </Flex>
              </form>
              <Badge textAlign={"right"}>Trials</Badge>
              {trials && !apiWaitTrials ? (
              <ClinicalTrialFrameCollection items={trials}
              overrideItems={({ item, idx }) => ({
                overrides: {
                  YesButton: {
                    as: "button",
                    onClick: () => {
                      //ConfettiExplosion({particleCount: 100, spread: 360, origin: { y: 0.6 }});
                      alert('Trial match feedback submitted.');
                    },
                    children: <Icon
                    ariaLabel="Good match"
                    pathData="M1 21h4v-12h-4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06l-1.06-1.05-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01.01-.08z"
                  />,
                  },
                  NoButton: {
                    as: "button",
                    onClick: () => {
                      //ConfettiExplosion({particleCount: 100, spread: 360, origin: { y: 0.6 }});
                      alert('Trial match feedback submitted.');
                    },
                    children: <Icon
                    ariaLabel="Bad match"
                    pathData="M15 3h-9c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01-.01.08c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06l1.06 1.05 6.59-6.59c.36-.36.58-.86.58-1.41v-10c0-1.1-.9-2-2-2zm4 0v12h4v-12h-4z"
                  />,
                  },
                  UnknownButton: {
                    as: "button",
                    display: "none",
                  }
                },
              })}
              />
              ) : <Flex direction='column' justifyContent={'center'}><h3>Fetching Trials</h3> <Loader variation="linear"/ ></Flex>}
            </Flex>
          </TabItem>
          <TabItem title="Edit">
            <UpdateFCPatient width="600px" fCPatient={patient} />
          </TabItem>
          <TabItem
            title={
              <>
                Files{" "}
                <Badge size="small" variation="info">
                  {patientFiles.length}
                </Badge>
              </>
            }
            isDisabled={patientFiles.length === 0}
          >
            <PatientFileCollection items={patientFiles} />
          </TabItem>
        </Tabs>
      </Flex>
    </>
  );
}

export default withAuthenticator(MyPatient);
export { getAge };
