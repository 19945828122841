import { Button, Flex, withAuthenticator } from "@aws-amplify/ui-react";

import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import React, { useState, CSSProperties, useEffect } from "react";

function PatientDetails() {
  const { id } = useParams();
  const [patient, setPatient] = useState();
  const [status, setStatus] = useState("loading");

  async function getPatientById(id: any) {
    //alert("getting pateint " + id);
    setStatus("getting patient: " + id);
    var requestTextBody = { "operation":"read", "payload":{"id":id} };
    console.log("requestTextBody: " + requestTextBody.toString());
    const myInit = {
      headers: {}, // OPTIONAL
      body: requestTextBody, // OPTIONAL
    };

    const p = await API.post("fcdataapi", "/patient", myInit);
  
    setPatient(p);
    console.log(p);
    console.log('.')
    setStatus("got patient: " + JSON.stringify(p));
    return p;
  }
  useEffect(() => {
    getPatientById(id);
  }, []);
  return (
    <div>
      <Flex margin="10em" direction="column" alignItems="center">
        <h2>Patient Summary</h2>
        <p>{status}</p>
        <Button onClick={() => getPatientById(id)}>Get Patient</Button>
      </Flex>
    </div>
  );
}
export default withAuthenticator(PatientDetails);
