import { Button, Divider, Flex, Heading, TextAreaField, TextField, withAuthenticator } from "@aws-amplify/ui-react";

import {
  DataStore,
  Amplify,
  API,
} from "aws-amplify";
import { json } from "react-router-dom";
import React, { useState, CSSProperties, useEffect } from "react";


function getData(requestText: string, apiName: string, path: string) {
  // const apiName = 'dataapi';
  // const path = '/api/ping';
  //let requestTextBody = { text1: requestText, text2: "test" };
  const myInit = {
    headers: {}, // OPTIONAL
    body: JSON.parse(requestText), // OPTIONAL
  }
  console.log("apiName: " + apiName);
  return API.post(apiName, path, myInit);
}

// (async function() {
//   console.log("in async function");
  
// })();


// const apiName = 'dataapi';
// const path = '/api/ping';
// const myInit = {
//   headers: {}, // OPTIONAL
//   response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
//   queryStringParameters: {
//     name: 'param' // OPTIONAL
//   }
// };

// API.get(apiName, path, myInit)
//   .then((response) => {
//     // Add your code here
//     console.log(response);
//   })
//   .catch((error) => {
//     console.log(error);
//   });


// async function callAPI() {
//   console.log("Calling API");
//   const response = await getData('');
//   console.log(response);
//   console.log("Completed Calling API");
// }



function ApiTester() {
  
  const [destinationTable, setDestinationTable] = useState("patient");
  const [ responseText, setResponseText ] = useState([]);
  
  const handleChange = (event: any) => {
    var value = event.target.value;
    setDestinationTable(value);
    console.log(destinationTable);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log("Submit");
    let request = event.target.inputText.value;
    let apiName = event.target.apiName.value;
    let path = event.target.apiPath.value;
    console.log(request);
    await getData(request,apiName,path).then((response) => {
      console.log(response);
      setResponseText(response);
    });
  };

  
  return (
    <>
      <Flex justifyContent={"center"}>
        <h1>API Test Utility</h1>
        </Flex>
        <Flex margin={'10em'} as="form" direction="column" onSubmit={handleSubmit}>
        <TextField
          descriptiveText="Enter API Name"
          placeholder="dataapi"
          label="API Name"
          name='apiName'
          errorMessage="There is an error"
        />
        
        <TextField
          descriptiveText="Enter API Path"
          placeholder="/api/ping"
          label="API Path"
          name="apiPath"
          errorMessage="There is an error"
        />
      <TextAreaField name='inputText' label="API Input" placeholder="{'text1':'patients','text2':'patience'}" isRequired={true} />
      <Button type="submit">Submit</Button>
      <TextAreaField name='responseText' label="API Response" isReadOnly={true} defaultValue={responseText} variation='quiet'/>

      
    </Flex>
        <Flex justifyContent={"center"}>
        <form>
          <label htmlFor="destinationTable">Destination Table: </label>
          <input type="text" onChange={handleChange} />
        </form>
      </Flex>
      <br/>

      <br />
      <br />
      {/* <h2 style={{ textAlign: "center" }}>Other Utilities</h2>
      <Flex justifyContent={"center"}>
        <Button onClick={() => callAPI()}>API Request</Button>
      </Flex> */}
    </>
  );
}

export default withAuthenticator(ApiTester);