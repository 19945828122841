// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { FCPatientFile, FCProvider, FCPatient, ClinicalTrialPatientLabel, ClinicalTrial, Patient } = initSchema(schema);

export {
  FCPatientFile,
  FCProvider,
  FCPatient,
  ClinicalTrialPatientLabel,
  ClinicalTrial,
  Patient
};