/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://5fjlbw3ii5dbnfqbfws5nutrmi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "fcdataapi",
            "endpoint": "https://fpdoet3n3a.execute-api.us-west-2.amazonaws.com/devel",
            "region": "us-west-2"
        },
        {
            "name": "getdoctext",
            "endpoint": "https://bsc00i1v9l.execute-api.us-west-2.amazonaws.com/devel",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:ad0b911c-b46c-4a4e-8da8-f3e8dcf64699",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_DdFjN11dc",
    "aws_user_pools_web_client_id": "71ps34h24l9g15bkgarbhqdemn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fight-chance-ai-storage-73dbd8a8134001-devel",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
