/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Badge, Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function FCPatientListItem(props) {
  const { fCPatient, overrides, ...rest } = props;
  return (
    <View
      width="796px"
      height="111px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "FCPatientListItem")}
      {...rest}
    >
      <View
        width="795.93px"
        height="112px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="-1px"
        left="0px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 1")}
      ></View>
      <Badge
        width="unset"
        height="unset"
        gap="0"
        position="absolute"
        top="10.81%"
        bottom="67.57%"
        left="2.26%"
        right="86.93%"
        size="small"
        variation="default"
        children="No Matches"
        {...getOverrideProps(overrides, "ReviewStatusFlag")}
      ></Badge>
      <Badge
        width="unset"
        height="unset"
        gap="0"
        position="absolute"
        top="10.81%"
        bottom="67.57%"
        left="16.58%"
        right="72.36%"
        size="small"
        variation="warning"
        children="Not Enrolled"
        {...getOverrideProps(overrides, "EnrollmentStatusTag")}
      ></Badge>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="calc(50% - 27px - -16.5px)"
        left="calc(50% - 204.48px - 175.52px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 3")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="408.96px"
          height="54px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 1")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="800"
            color="rgba(0,0,0,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="315.57px"
            height="24.24px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0%"
            bottom="55.1%"
            left="0%"
            right="22.83%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={`${fCPatient?.firstName}${" "}${fCPatient?.lastName}`}
            {...getOverrideProps(overrides, "Name")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="408.96px"
            height="29.76px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="44.9%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={fCPatient?.diagnoses}
            {...getOverrideProps(overrides, "Conditions")}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="3px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="calc(50% - 39.2px - 0.3px)"
        left="calc(50% - 139.17px - -168.17px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 2")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="208.71px"
          height="23.88px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"Sex: "}${fCPatient?.sex}`}
          {...getOverrideProps(overrides, "Sex")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="208.71px"
          height="24.83px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"DOB: "}${fCPatient?.dateOfBirth}`}
          {...getOverrideProps(overrides, "Age")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="278.33px"
          height="23.7px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={fCPatient?.email}
          {...getOverrideProps(overrides, "DOB")}
        ></Text>
      </Flex>
      <View
        padding="0px 0px 0px 0px"
        width="32px"
        height="43px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="33px"
        left="745px"
        {...getOverrideProps(overrides, "ArrowButton")}
      >
        <Icon
          width="32px"
          height="43px"
          viewBox={{ minX: 0, minY: 0, width: 32, height: 43 }}
          paths={[
            {
              d: "M8.72727 0L0 8.0625L14.5455 21.5L0 34.9375L8.72727 43L32 21.5L8.72727 0Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </View>
    </View>
  );
}
