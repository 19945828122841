import {
  Button,
  Divider,
  FileUploader,
  Flex,
  Heading,
  Loader,
  TextAreaField,
  withAuthenticator,
} from "@aws-amplify/ui-react";

import { DataStore, Amplify, API, Storage, Predictions } from "aws-amplify";
import { json } from "react-router-dom";
import React, { useState, CSSProperties, useEffect } from "react";
import { FCPatientFile } from "./models";
function DocImport({addPatientFile}) {
  const [response, setResponse] = useState("Add a file to identify text.");
  const [apiWait, setApiWait] = useState(false);

  function getText(url: string) {
    const apiName = 'getdoctext';
    const path = '/api/gettext';
    //var requestTextBody = 'url:"'+url+'"';
    //var url = 'test'
    var requestTextBody = {"url":url};
    console.log("requestTextBody: " + requestTextBody)
    const myInit = {
      headers: {}, // OPTIONAL
      body: requestTextBody, // OPTIONAL
    }
    console.log("apiName: " + apiName);
    return API.post(apiName, path, myInit);
  }

  async function processFile({ key }) {
    // alert("processing file: " + key);
    setApiWait(true);
    setResponse("processing file: " + key);
    const url = await Storage.get(key, { level: 'private'});
    
    await getText(url).then(async (response) => {
      console.log(response);
      setResponse(response);
      console.log("calling addPatientFile");
      await addPatientFile(key,response);
    });
    setApiWait(false);
  }
  
  return (
    <div>
      <Flex justifyContent={'center'} direction="column" gap={'1em'} >
        <h4>Document Import</h4>
        <p>Upload a document to identify text.</p>
        <FileUploader 
          isPreviewerVisible={true}
          isResumable={true}
          acceptedFileTypes={[".png", ".pdf", ".bmp", ".doc", ".jpeg", ".jpg"]}
          accessLevel="private"
          onSuccess={processFile}
        />
        {apiWait && <><p>Extracting Text</p><Loader variation="linear" /> </>}
      </Flex>
    </div>
  );
}

export default withAuthenticator(DocImport);
