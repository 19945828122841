import {Alert, Flex, Button, withAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import FCProviderCreateForm from "./ui-components/FCProviderCreateForm";
import FCProviderUpdateForm from "./ui-components/FCProviderUpdateForm";
import { DataStore } from "aws-amplify";
import { FCProvider } from "./models";
import HeroLayout1 from "./ui-components/HeroLayout1";


function Welcome({ user,navigate }) {
  const [hasProfile, setHasProfile] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [editForm, setEditForm] = useState(false);
  const [patientCount, setPatientCount] = useState(0);
  async function checkProviderProfile() {
    console.log("checking user profile");
    console.log(user);
    let profile = await DataStore.query(FCProvider, (c) =>
      c.username.eq(user.username)
    );

    console.log("user profile: ");
    console.log(profile);
    if (profile?.length > 0) {
      console.log("user profile exists");
      var patients = await profile[0].FCPatients.values;
      console.log(patients);
      console.log("patients: " + patients);
      setPatientCount(patients?.length)
      setHasProfile(true);
      setUserProfile(profile[0]);
    } else {
      console.log("user profile does not exist");
      setHasProfile(false);
      setEditForm(true);
      setUserProfile(null);
    }
  }

  useEffect(() => {
    checkProviderProfile();
    return () => {
    };
  }, []);

  return (
    <Flex direction="column" alignItems={"center"} justifyContent={"center"}>
      {!userProfile ? (
        <>
          <h1>Let's get started!</h1>
          <h2>Complete your personal profile</h2>
          <Button onClick={()=>{setEditForm(true)}}> Edit Profile</Button>
          {editForm ? (
            <FCProviderCreateForm width="600px"></FCProviderCreateForm>
          ) : null}
        </>
      ) : (
  <>
      
          <h1>
            Welcome back {userProfile.firstName} {userProfile.lastName}!
          </h1>
          <h4> You have {patientCount} patients with <b>new</b> matching trials.</h4>
          <Button variation="primary" onClick={() => {navigate('/patients')}}>Review Now!</Button>
          {/* <h2>Edit your profile.</h2> */}
          {/* <FCProviderUpdateForm
            fCProvider={userProfile}
            width="600px"
          ></FCProviderUpdateForm> */}
          {/* <Button onClick={() => {navigate("/patients")}}>Continue</Button> */}
        </>
  
      )}
    </Flex>
  );
}
export default withAuthenticator(Welcome);
